import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://23f9fe3008439fdbc379a96e5af1d8ba@o4506325509931008.ingest.sentry.io/4506325524807680",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});